<template>
  <Form class="wrapper" ref="target" v-slot="{ errors }">
    <img class="wrapper__img" src="images/user.png" />
    <div class="wrapper__input">
      <Field
        class="wrapper__input__content"
        name="username"
        :rules="checkUsername"
        placeholder="请输入用户名"
        v-model="registerForm.username"
      />
    </div>
    <div class="error" v-if="errors.username">
      {{ errors.username }}
    </div>
    <div class="wrapper__input">
      <Field
        type="password"
        class="wrapper__input__content"
        name="Pwd"
        :rules="checkRegisterPassword"
        placeholder="请输入密码"
        autocomplete="new-password"
        v-model="registerForm.password"
      />
    </div>
    <div class="error" v-if="errors.Pwd">
      {{ errors.Pwd }}
    </div>
    <div class="wrapper__input">
      <Field
        class="wrapper__input__content"
        name="confirmPwd"
        :rules="checkRegisterPassword"
        placeholder="确认密码"
        type="password"
        v-model="registerForm.confirmPassword"
        @keyup.enter="handleRegister"
      />
    </div>
    <div class="error" v-if="errors.confirmPwd">
      {{ errors.confirmPwd }}
    </div>
    <div class="wrapper__register-button" @click="handleRegister">注册</div>
    <div class="wrapper__register-link" @click="handleLoginClick">
      已有账号去登录
    </div>
    <Toast v-if="show" :message="toastMessage" />
  </Form>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import { useStore } from 'vuex'
import { Notify } from '@nutui/nutui'
import { Field, Form } from 'vee-validate'
import { useFormValidate } from '@/utils/validate'

const router = useRouter()

const registerForm = ref({
  username: '',
  password: '',
  confirmPassword: ''
})
const target = ref(null)
// 表单校验方法
const { checkUsername, checkRegisterPassword } = useFormValidate()

const store = useStore()

// 注册
const handleRegister = async () => {
  try {
    const valid = await target.value.validate()
    if (valid.valid) {
      if (
        registerForm.value.username.trim() === '' ||
        registerForm.value.password.trim() === ''
      ) {
        Notify.danger('用户名和密码不能为空')
        return
      }
      if (registerForm.value.password !== registerForm.value.confirmPassword) {
        Notify.danger('两次密码不一致，请重新输入')
        registerForm.value.password = ''
        registerForm.value.confirmPassword = ''
        return
      }

      store
        .dispatch('user/userRegister', registerForm.value)
        .then((data) => {
          Notify.success('注册成功', {
            duration: 2000,
            onClose() {
              router.push({ name: 'Login' })
            }
          })
        })
        .catch((error) => {
          Notify.danger(error, {
            duration: 3000
          })
        })
    }
  } catch (error) {
    Notify.danger(error, {
      duration: 3000
    })
  }
}

// 处理登陆跳转
const handleLoginClick = () => {
  router.push({ name: 'Login' })
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
.wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  &__img {
    display: block;
    margin: 0 auto 0.4rem auto;
    width: 0.66rem;
    height: 0.66rem;
  }
  &__input {
    height: 0.48rem;
    margin: 0 0.4rem 0.16rem 0.4rem;
    padding: 0 0.16rem;
    background: #f9f9f9;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    border-radius: 6px;
    &__content {
      line-height: 0.48rem;
      border: none;
      outline: none;
      width: 100%;
      background: none;
      font-size: 0.16rem;
      color: $content-notice-fontcolor;
      &::placeholder {
        color: $content-notice-fontcolor;
      }
    }
  }
  &__register-button {
    margin: 0.32rem 0.4rem 0.16rem 0.4rem;
    line-height: 0.48rem;
    background: $btn-bgColor;
    box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 145, 255, 0.32);
    border-radius: 0.04rem;
    border-radius: 0.04rem;
    color: $bgColor;
    font-size: 0.16rem;
    text-align: center;
  }
  &__register-link {
    text-align: center;
    font-size: 0.14rem;
    color: $content-notice-fontcolor;
  }
}
.error {
  font-size: 0.12rem;
  margin: 0 0.4rem 0.16rem 0.4rem;
  padding: 0 0.16rem;
  color: $hightlight-fontColor;
}
</style>
